@import 'styles/breakpoints';

.testimonial {
    display: flex;
    width: 100%;
    max-width: var(--testimonial-max-width, 32.7rem);
    flex-direction: column;
    background-color: var(--testimonial-background-color, none);
    color: var(--testimonial-color, var(--colors-blue));
    text-align: center;
}

.iconWrapper {
    position: relative;
    width: 9.2rem;
    height: 6.5rem;
    margin-bottom: 2.4rem;
    color: var(--icon-wrapper-color);
}

.lineBlock {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2.4rem 0;
}

.line {
    display: block;
    width: 4.8rem;
    border-top: var(--line-border-top, 2px solid var(--colors-blue));
}

.innerBlock {
    position: relative;
}

.textWrapper {
    position: relative;
}

.textBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.authorBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.imageBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--imgWrapper-margin, 0 0 1.6rem 0);
}

.img {
    width: 14rem;
    object-fit: cover;
    object-position: center;
}

.authorName {
    margin: 0 0 1rem;
}

.authorDescription {
    margin: 0;
}

.blueBlockTestimonial {
    --testimonial-background-color: var(--colors-blue);
    --testimonial-color: var(--colors-white);
    --line-border-top: 2px solid var(--colors-cream);
    --icon-wrapper-color: var(--colors-white);

    .iconWrapper {
        svg {
            opacity: 0.1;
        }
    }
}

.blueSolidIconBlockTestimonial {
    --testimonial-background-color: var(--colors-blue);
    --testimonial-color: var(--colors-white);
    --line-border-top: 2px solid var(--colors-cream);
    --icon-wrapper-color: var(--colors-white);
}

.whiteBlockTestimonial {
    --icon-wrapper-color: var(--colors-cream);
}

.secondaryQuote {
    margin-top: 1rem;
}

@include medium {
    .testimonial {
        --testimonial-max-width: 60rem;
        --imgWrapper-margin: 0 0 2.8rem 0;
    }

    .largeQuote {
        --testimonial-max-width: 85rem;
    }

    .blueBlockTestimonial {
        align-items: center;
        text-align: center;

        div {
            align-items: center;
        }
    }

    .img {
        width: 16rem;
    }
}
