@import 'styles/breakpoints';

.creameryDiningHoursFlex {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--creameryDiningHoursFlex-padding, 0);
}

.creameryDiningHours {
    display: flex;
    width: 100%;
    max-width: 41.6rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.richEyebrow {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
}

.headline {
    margin-top: var(--headline-margin, 1.2rem);
}

.iconWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    margin: var(--iconWrapper-margin, 2rem 0 4rem);

    .icon {
        position: relative;
        width: var(--icon-width, 4.8rem);
        height: var(--icon-height, 1.1rem);
    }
}

.list {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
    padding: 2.4rem 0;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.listItem {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 0;
    border-bottom: 0.1rem solid rgba(0, 30, 96, 0.25);
}

.listItemLabel {
    display: flex;
    align-items: center;
    justify-content: center;
}

.listItemLabelIcon {
    position: relative;
    display: flex;
    width: 2.2rem;
    height: 2.4rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1.6rem;
}

.listItemLabelIconInner {
    position: relative;
    display: flex;

    width: 2.6rem;
    height: 2.6rem;
    align-items: flex-start;
    justify-content: center;

    svg {
        width: 2.6rem;
        height: 2.6rem;
    }
}

.listItemLabelText {
    text-transform: uppercase;
}

@include medium {
    .creameryDiningHours {
        --headline-margin: 2.2rem;

        --iconWrapper-margin: 2.8rem 0 4.2rem;
        --icon-width: 6rem;
        --icon-height: 1.3rem;
    }
}

@include xxLarge {
    .creameryDiningHoursFlex {
        --creameryDiningHoursFlex-padding: 0 7.8rem 0 0;
    }
}
